import { Button, Container, Heading, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useTranslations } from 'use-intl';
import { SPOTLIGHT } from '../../constants/enums';
import { BACKSTAGE_URL } from '../../constants/variables';
import useIsBackstageRoute from '../../hooks/isBackstageRoute';
import Spotlight from '../ui/Spotlight';

interface Props {
  page: '404' | '500';
}

export default function ErrorPage(props: Props) {
  const { page } = props;

  const t = useTranslations(`Global.${page}`);
  const isBackstageRoute = useIsBackstageRoute();

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '80vh',
    position: 'relative',
    overflow: 'hidden',
  } as const;

  const buttonStyle = {
    alignSelf: 'flex-start',
    marginTop: 8,
  };

  const redirect = isBackstageRoute
    ? {
        href: `${BACKSTAGE_URL}/queen-hub`,
        text: t('backToQueenHub'),
      }
    : {
        href: `/`,
        text: t('backToHome'),
      };

  return (
    <Container size="lg" sx={containerStyle}>
      <Spotlight option={SPOTLIGHT.RIGHT_1} />
      <Container size="md" sx={containerStyle}>
        <Heading as="h1" size="h-2xl">
          {t('heading')}
        </Heading>
        <Text>{t('description')}</Text>
        <NextLink href={redirect.href} passHref>
          <Button sx={buttonStyle}>{redirect.text}</Button>
        </NextLink>
      </Container>
    </Container>
  );
}
