import { GetStaticPropsContext } from 'next';
import ErrorPage from '../components/page/ErrorPage';

export default function Custom404() {
  return (
    <>
      <ErrorPage page="404" />
    </>
  );
}

export function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: {
        ...require(`../messages/global/${locale}.json`),
      },
    },
  };
}
